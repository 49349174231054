import { Carousel } from '@trendyol-js/react-carousel';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import logo1 from '../../../../assets/vcard/bg/00 1.png';
import logo2 from '../../../../assets/vcard/bg/01 2.png';
import logoStandard1 from '../../../../assets/vcard/logo-standard/FSSC 22000.png';
import logoStandard2 from '../../../../assets/vcard/logo-standard/SGS ISO 9001.png';
import logoStandard3 from '../../../../assets/vcard/logo-standard/Halal.png';
import logoStandard4 from '../../../../assets/vcard/logo-standard/FDA.png';
import Pic1 from '../../../../assets/vcard/bg/00 5.png';
import Pic3 from '../../../../assets/vcard/bg/01 1.png';
import Pic4 from '../../../../assets/vcard/bg/02 1.png';
import Pic6 from '../../../../assets/vcard/bg/03 1.png';
import Pic9 from '../../../../assets/vcard/bg/03 2.png';
import logoBrand1 from '../../../../assets/vcard/logo-brand/fibercreme.png';
import logoBrand2 from '../../../../assets/vcard/logo-brand/RichCreme.png';
import logoBrand3 from '../../../../assets/vcard/logo-brand/Lautan Dairy Series.png';
import logoBrand4 from '../../../../assets/vcard/logo-brand/Lautan Premix Series.png';
import logoBrand5 from '../../../../assets/vcard/logo-brand/Lautan Krimer Series.png';
import logoSocialMedia1 from '../../../../assets/vcard/logo-social-media/Instagram.png';
import logoSocialMedia2 from '../../../../assets/vcard/logo-social-media/LinkedIn.png';
import logoSocialMedia3 from '../../../../assets/vcard/logo-social-media/Youtube.png';
import '../../../../assets/vcard/css/style.css';
import Icon from '../../../../components/icon/Icon';
import Button from '../../../../components/bootstrap/Button';
import EmployeeModule from '../../../../modules/v2/handri/EmployeeModule';
import Avatar from '../../../../components/Avatar';
import InputPassword from '../../rio/InputPassword';
import PrivacySettingModule from '../../../../modules/v2/rio/PrivacySettingModule';

const VCard = () => {
	const [employee, setEmployee] = useState(null);
	const { nik } = useParams();
	const setPrivate = localStorage.getItem('setPrivate');
	const setUser = JSON.parse(localStorage.getItem('setUser'));

	const fetchData = async (nik_user) => {
		return EmployeeModule.read(nik_user).then((results) => {
			setEmployee(results);
		});
	};

	const generateVCF = () => {
		const linkElement = document.createElement('a');
		linkElement.setAttribute(
			'href',
			`data:${employee.vcard.mimetype};base64,${employee.vcard.file}`,
		);
		linkElement.setAttribute('download', `${nik}.vcf`);
		linkElement.style.display = 'none';

		document.body.appendChild(linkElement);
		linkElement.click();
		document.body.removeChild(linkElement);
	};

	const fetchPrivacySetting = async () => {
		await PrivacySettingModule.read(nik)
			.then((res) => {
				if (!res.is_private) {
					localStorage.removeItem('setPrivate');
				} else if (res.is_private) {
					localStorage.setItem('setPrivate', true);
				}
				fetchData(nik);
			})
			.catch(() => {})
			.finally(() => {});
	};

	useEffect(() => {
		if (!setUser) {
			fetchPrivacySetting();
		} else {
			if (new Date().getTime() > setUser?.expired) {
				localStorage.removeItem('setUser');
				fetchPrivacySetting();
			}
			fetchData(nik);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (setPrivate) {
		return <InputPassword />;
	}

	return (
		<PageWrapper title='Landing Page' className='p-0'>
			<div className='content-wrapper'>
				<div className='row align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8' style={{ marginTop: '-50px' }}>
						<div className='section-1'>
							<img src={Pic1} id='pic-1' alt='bg-1' />
							<img src={logo1} id='logo-1' alt='bg-1' />
							{employee && employee.photo != undefined && employee.photo != '' ? (
								// <img
								// 	style={{
								// 		position: 'absolute',
								// 		top: '59%',
								// 		right: '39px',
								// 		width: '36%',
								// 		borderRadius: '50%',
								// 	}}
								// 	src={`data:${employee.photo.mimetype};base64,${employee.photo.file}`}
								// 	alt='photo_user'
								// />
								<Avatar
									src={`data:${employee.photo.mimetype};base64,${employee.photo.file}`}
									className='avatar rounded-circle bg-l25-primary'
									alt='Employee Photo'
									style={{
										position: 'absolute',
										top: '56%',
										right: '25px',
										width: '40%',
										height: '40%',
									}}
								/>
							) : (
								''
							)}
							<p className='employee-name'>{employee ? employee.name : ''}</p>
							<p className='employee-position'>{employee ? employee.position : ''}</p>
						</div>
						<div className='section-2'>
							<div className='wrapper'>
								<div>
									<table className='table-vcard'>
										<tr>
											<td className='icon'>
												<Icon icon='Phone' />{' '}
											</td>
											<td>{employee ? employee.phone_number : ''}</td>
										</tr>
										<tr>
											<td className='icon'>
												<Icon icon='Envelope' />{' '}
											</td>
											<td>{employee ? employee.email : ''}</td>
										</tr>
										<tr>
											<td className='icon'>
												<Icon icon='PinMap' />{' '}
											</td>
											<td>{employee ? employee.address : ''}</td>
										</tr>
									</table>
								</div>
								<div
									style={{
										marginTop: '10%',
										display:
											employee && employee.vcard != '' ? 'block' : 'none',
									}}
									className='text-center'>
									<Button
										style={{
											backgroundColor: '#A2DDD4',
											borderColor: '#A2DDD4',
											color: '#000',
										}}
										type='button'
										size='sm'
										onClick={() => generateVCF()}>
										Save To Contact
									</Button>
								</div>
							</div>
						</div>
						<img src={Pic3} alt='bg-1' className='section-3' />
						<div className='text-center'>
							<img
								src={logo2}
								alt='bg-1'
								style={{
									position: 'relative',
									width: '55%',
								}}
							/>
						</div>
						<p style={{ marginTop: '30px', padding: '0 30px' }}>
							PT Lautan Natural Krimerindo is a leading company of food and beverage
							ingredients, specialized in spray dried ingredients and powder premixes.
							We always create value by only using the finest quality ingredients
							processed in the state of the art facility to deliver the best products
							for customers.
						</p>
						<div className='container' style={{ marginTop: '30px', padding: '0 30px' }}>
							<div className='row'>
								<div className='col'>
									<img src={logoStandard1} alt='bg-1' style={{ width: '100%' }} />
								</div>
								<div className='col'>
									<img src={logoStandard2} alt='bg-1' style={{ width: '100%' }} />
								</div>
								<div className='col'>
									<img src={logoStandard3} alt='bg-1' style={{ width: '100%' }} />
								</div>
								<div className='col'>
									<img src={logoStandard4} alt='bg-1' style={{ width: '100%' }} />
								</div>
							</div>
						</div>
						<div className='box-video'>
							<div className='wrapper'>
								<div className='video'>
									<ReactPlayer
										width='100%'
										height='100%'
										url={employee?.contact_us?.watch_youtube}
									/>
								</div>
								<div style={{ margin: '10px 20px' }}>
									<a
										href={employee?.contact_us?.website}
										style={{
											textDecoration: 'none',
											color: '#409CFF',
											fontWeight: '600',
										}}>
										Visit Website
									</a>
								</div>
							</div>
						</div>
						<div>
							<img
								src={Pic4}
								alt='bg-1'
								style={{
									width: '100%',
								}}
							/>
							<div className='section-5'>
								<h4 style={{ color: '#1460B0', fontWeight: 'bold' }}>
									Selection Of Brands
								</h4>
								<div
									className='container'
									style={{ marginTop: '15px', padding: '0 30px' }}>
									<div className='row'>
										<Carousel
											className='exampleCarousel1'
											show={4}
											slide={1}
											swiping='true'
											responsive='true'
											rightArrow={
												<Icon
													icon='ArrowRight'
													size='2x'
													style={{ cursor: 'pointer' }}
												/>
											}
											leftArrow={
												<Icon
													icon='ArrowLeft'
													size='2x'
													style={{ cursor: 'pointer' }}
												/>
											}>
											<div className='col'>
												<img
													src={logoBrand1}
													alt='bg-1'
													style={{ width: '70%' }}
												/>
											</div>
											<div className='col'>
												<img
													src={logoBrand2}
													alt='bg-1'
													style={{ width: '70%' }}
												/>
											</div>
											<div className='col'>
												<img
													src={logoBrand3}
													alt='bg-1'
													style={{ width: '70%' }}
												/>
											</div>
											<div className='col'>
												<img
													src={logoBrand4}
													alt='bg-1'
													style={{ width: '70%' }}
												/>
											</div>
											<div className='col'>
												<img
													src={logoBrand5}
													alt='bg-1'
													style={{ width: '70%' }}
												/>
											</div>
										</Carousel>
									</div>
								</div>
								<h4
									style={{
										marginTop: '40px',
										color: '#1460B0',
										fontWeight: 'bold',
									}}>
									Follow Our Social Media
								</h4>
								<div
									className='container'
									style={{
										marginTop: '15px',
										padding: '0 30px',
									}}>
									<div
										className='row row-cols-3 gy-4 gutter-custom'
										style={{ padding: '0 20px' }}>
										<div className='col-4 social-media'>
											<a
												href={employee?.contact_us?.instagram}
												style={{ textDecoration: 'none' }}>
												<div
													className='row'
													style={{
														backgroundColor: '#FFFFFF',
														height: '40%',
													}}>
													&nbsp;
												</div>
												<div
													className='row'
													style={{
														backgroundColor: '#2061B0',
														height: '60%',
													}}>
													<div
														style={{
															color: 'white',
															bottom: 0,
															position: 'absolute',
														}}
														className='text-social-media'>
														<div style={{ fontSize: '5pt' }}>
															Instagram
														</div>
														<div>@LautanNaturalKrimerindo</div>
													</div>
												</div>
												<div
													className='row mt-1'
													style={{
														backgroundColor: '#DFB68A',
														height: '5%',
													}}>
													&nbsp;
												</div>
												<div
													style={{
														position: 'absolute',
														top: '5%',
														left: '0px',
													}}>
													<img
														src={logoSocialMedia1}
														alt='bg-1'
														className='logo-social-media'
													/>
												</div>
											</a>
										</div>
										<div className='col-4 social-media'>
											<a
												href={employee?.contact_us?.linkedin}
												style={{ textDecoration: 'none' }}>
												<div
													className='row'
													style={{
														backgroundColor: 'white',
														height: '40%',
													}}>
													&nbsp;
												</div>
												<div
													className='row'
													style={{
														backgroundColor: '#2061B0',
														height: '60%',
													}}>
													<div
														style={{
															color: 'white',
															bottom: 0,
															position: 'absolute',
														}}
														className='text-social-media'>
														<div style={{ fontSize: '5pt' }}>
															LinkedIn
														</div>
														<div>Lautan Natural Krimerindo</div>
													</div>
												</div>
												<div
													className='row mt-1'
													style={{
														backgroundColor: '#DFB68A',
														height: '5%',
													}}>
													&nbsp;
												</div>
												<div
													style={{
														position: 'absolute',
														top: '5%',
														left: '0px',
													}}>
													<img
														src={logoSocialMedia2}
														alt='bg-1'
														className='logo-social-media'
													/>
												</div>
											</a>
										</div>
										<div className='col-4 social-media'>
											<a
												href={employee?.contact_us?.youtube}
												style={{ textDecoration: 'none' }}>
												<div
													className='row'
													style={{
														backgroundColor: 'white',
														height: '40%',
													}}>
													&nbsp;
												</div>
												<div
													className='row'
													style={{
														backgroundColor: '#2061B0',
														height: '60%',
													}}>
													<div
														style={{
															color: 'white',
															bottom: 0,
															position: 'absolute',
														}}
														className='text-social-media'>
														<div style={{ fontSize: '5pt' }}>
															Youtube
														</div>
														<div>Lautan Natural Krimerindo</div>
													</div>
												</div>
												<div
													className='row mt-1'
													style={{
														backgroundColor: '#DFB68A',
														height: '5%',
													}}>
													&nbsp;
												</div>
												<div
													style={{
														position: 'absolute',
														top: '5%',
														left: '0px',
													}}>
													<img
														src={logoSocialMedia3}
														alt='bg-1'
														className='logo-social-media'
													/>
												</div>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<img
								src={Pic6}
								alt='bg-1'
								style={{
									width: '100%',
								}}
							/>
						</div>
						<div className='section-7'>
							<div
								className='container'
								style={{ marginTop: '60px', padding: '0 30px' }}>
								<div className='row'>
									<div className='col-3'>
										<img
											src={Pic9}
											alt='bg-1'
											style={{
												width: '100%',
											}}
										/>
									</div>
									<div className='col-9' style={{ color: 'white' }}>
										Jl. Raya Mojosari - Pacet Km. 4,
										<br />
										Ds. Pesanggrahan, Kab. Mojokerto, <br />
										East Java - Indonesia <br />
										<br />
										Phone: {employee?.contact_us?.phone}
										<br />
										Fax: {employee?.contact_us?.fax}
										<br />
										<br />
										<table>
											<tr>
												<td>Business</td>
												<td>:</td>
												<td>{employee?.contact_us?.business}</td>
											</tr>
											<tr>
												<td>Career</td>
												<td>:</td>
												<td>{employee?.contact_us?.career}</td>
											</tr>
											<tr>
												<td>Other inquiry</td>
												<td>:</td>
												<td>{employee?.contact_us?.inquiry}</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div className='section-8'>
							Copyright © {new Date().getFullYear()} PT Lautan Natural Krimerindo. All
							rights reserved
						</div>
					</div>
				</div>
			</div>
		</PageWrapper>
	);
};

export default VCard;
