import Service from '../../../services/v2/rio/privacy-setting.service';

const create = (payload) => {
	return Service.create(payload).then(
		(response) => Promise.resolve(response.data, response.data.message),
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const read = (payload, query) => {
	return Service.read(payload, query).then(
		(response) => Promise.resolve(response.data, response.data.message),
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const checkPassword = (payload) => {
	return Service.checkPassword(payload).then(
		(response) => Promise.resolve(response.data, response.data.message),
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default { create, read, checkPassword };
