/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unused-vars */
import { Carousel } from '@trendyol-js/react-carousel';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import InputPasswordBg from '../../../assets/vcard/bg/input-password-bg.png';
import '../../../assets/vcard/css/style.css';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import PrivacySettingModule from '../../../modules/v2/rio/PrivacySettingModule';
import Spinner from '../../../components/bootstrap/Spinner';
import Modal, { ModalBody } from '../../../components/bootstrap/Modal';

const LoadingModal = (dt) => {
	const { loading, setLoading, onlyContent } = dt;
	const renderComponent = (
		<Button color={onlyContent ? 'info' : 'primary'} isLight={onlyContent}>
			<Spinner isSmall inButton='onlyIcon' isGrow />
			&nbsp;
			<Spinner isSmall inButton='onlyIcon' isGrow />
			&nbsp;
			<Spinner isSmall inButton='onlyIcon' isGrow />
			&nbsp; Loading...
		</Button>
	);

	if (onlyContent) return renderComponent;

	return (
		<Modal isOpen={loading} size='sm' isCentered setIsOpen={setLoading} isStaticBackdrop>
			<ModalBody
				style={{ backgroundColor: '#6c5dd3', color: 'white' }}
				className='text-center'>
				{renderComponent}
			</ModalBody>
		</Modal>
	);
};

const InputPassword = () => {
	const { nik } = useParams();
	const [loading, setLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			password: '',
		},
		validationSchema: Yup.object({
			password: Yup.string().required('Password is required'),
		}),
		onSubmit: async (values) => {
			try {
				await PrivacySettingModule.checkPassword({ nik, password: values.password }).then(
					(res) => {
						Swal.fire({
							heightAuto: false,
							title: 'Profile unlocked !',
							text: 'You can now access the profile',
							icon: 'success',
						}).then(() => {
							localStorage.setItem(
								'setUser',
								JSON.stringify({
									username: res.username,
									name: res.name,
									expired: new Date().getTime() + 30 * 60 * 1000,
								}),
							);
							localStorage.removeItem('setPrivate');
							window.location.reload();
							formik.resetForm();
						});
					},
				);
			} catch (error) {
				Swal.fire({
					title: 'Try Again',
					text: 'Incorrect Password!',
					icon: 'error',
				});
			}
		},
	});

	return (
		<>
			<LoadingModal loading={loading} setLoading={setLoading} />
			<PageWrapper title='Input Password' className='p-0'>
				<div className='content-wrapper'>
					<div className='row align-items-center justify-content-center'>
						<div
							className='col-xl-4 col-lg-6 col-md-8'
							style={{
								height: '100vh',
								backgroundImage: `url(${InputPasswordBg})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center',
							}}>
							<div className='d-flex justify-content-center align-items-center h-100 w-100'>
								<Card className='w-75'>
									<CardBody>
										<div className='d-flex justify-content-center flex-column align-items-center'>
											<p className='h2'>Input Password</p>
											<p className='text-muted'>
												Give your phone to the profile owner
											</p>
											<FormGroup className='mt-3 w-100'>
												<Input
													placeholder='Password'
													type='password'
													autoComplete='off'
													id='password'
													name='password'
													onChange={(e) =>
														formik.setFieldValue(
															'password',
															e.target.value,
														)
													}
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															formik.handleSubmit();
														}
													}}
													onBlur={formik.handleBlur}
													value={formik.values.password}
													isValid={formik.isValid}
													isTouched={formik.touched.password}
													invalidFeedback={formik.errors.password}
												/>
											</FormGroup>
											<Button
												onClick={formik.handleSubmit}
												color='info'
												className='mt-5 w-100'
												style={{ height: '40px' }}>
												Submit
											</Button>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</PageWrapper>
		</>
	);
};

export default InputPassword;
