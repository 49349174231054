import axios from 'axios';
import authHeader from '../../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}privacy-setting`, payload, {
		headers: await authHeader(),
	});
};

const read = async (username, query = {}) => {
	return axios.get(`${API_URL_DEFAULT}privacy-setting/${username}`, {
		headers: await authHeader(),
		params: query,
	});
};

const checkPassword = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}privacy-setting/check-password`, payload, {
		headers: await authHeader(),
	});
};

export default { create, read, checkPassword };
